
import { defineComponent } from "vue";
import { mapActions, mapGetters, useStore } from "vuex";
import Receipts from "@/types/receipts";
import FormOptionsDialog from "../FormOptionsDialog.vue";
import PO from "@/types/po";

import { useNotifications } from "@/composables/Notification/useNotifications";

import ReceiptsView from "./ReceiptsView.vue";
import ModalSpinner from "@/components/UI/ModalSpinner.vue";
import { forEach } from "lodash";
import { NotificationType } from "@/types/notification";

import ReceiptsService from '@/services/ReceiptsService';

const receiptsService = new ReceiptsService();

export default defineComponent({
  components: {
    ReceiptsView,
    ModalSpinner,
    FormOptionsDialog
  },
  setup() {
      const store = useStore();
    const {
      addSuccessNotification,
      addWarnNotification,
      addErrorNotification,
    } = useNotifications(store);
return { addSuccessNotification,
      addWarnNotification,
      addErrorNotification,
}
  },
  async created() {
    await this.fetchFdicts("RECEIPTS");
    this.fetchControls({
        id: "FORM",
        procedure: "FORM.CONTROL",
        filename: "CONTROL",
      });
  },
  computed: {
    ...mapGetters({
      getActiveReceipts: "inventoryModule/getActiveReceipts",
    }),
  },
  data() {
    return {
      loading: false,
      showFormOptions: false,
      currentReceiptId: "",
    };
  },
  methods: {
    ...mapActions({
      addNotification: "notification/add",
      createReceiptsById: "inventoryModule/createReceiptsById",
      updateReceiptsById: "inventoryModule/updateReceiptsById",
      createReceiptsFromPurchaseOrder:
        "inventoryModule/createReceiptsFromPurchaseOrder",
      postReceiptsById: "inventoryModule/postReceiptsById",
      fetchFdicts: "fdict/fetchFdict",
      fetchControls: "control/fetchControl",
      getReceiptPDF: "inventoryModule/getReceiptPDF",
    }),
    downloadPdf (id: string, report: string, pageWidth: string) {
      this.getReceiptPDF({
          recordId: id,
          reportName: report,
          pageWidth: pageWidth,
        });
    },
    sendEmail(id: string, reportName: string, pageWidth: string, email: any) {
      receiptsService.getReceiptPdf({id, reportName, pageWidth, email})
        .then((response) => {
          if (response === "success") {
            this.addSuccessNotification(`Receipt has been emailed successfully`)
          } else {
            this.addErrorNotification(response || `Receipt was not sent`);
          }
        })
        .catch((error) => {
          this.addErrorNotification(`Receipt could not be sent: ${error}`);
        });
    },
    async handleSaveReceipts(receipts: any) {
      this.loading = true;
      if (this.getActiveReceipts.record.receipts_id) {
        await this.handleUpdateReceipts(receipts);
      } else {
        await this.handleCreateReceipts(receipts);
      }
      this.loading = false;
    },
    async handleCreateReceipts(receipts: Receipts) {
      const response = await this.createReceiptsById({
        receipts: receipts,
        id: this.getActiveReceipts.id,
      });

      const { recordId } = response;

      if (recordId) {
        this.showFormOptions = true;
        this.currentReceiptId = recordId;
        if(response.metaData?.info) {
          const info = response.metaData.info;
          forEach(info, (value, key) => {
            this.addNotification({
              type: NotificationType.INFO,
              message: value.content,
              dialog: true,
              title: value.title,
              subtitle: value.subtitle,
            })
          })
        }
        else {
          this.addSuccessNotification(`Receipts #${recordId} created successfully`)
        }
      }
    },
    async handleUpdateReceipts(receipts: Receipts) {
      const response = await this.postReceiptsById({
        receipts: receipts,
        id: this.getActiveReceipts.id,
      });

      const { status } = response;

      if (status === "success") {
        this.showFormOptions = true;
        this.currentReceiptId = receipts.receipts_id;
        if(response.metaData?.info) {
          const info = response.metaData.info;
          forEach(info, (value, key) => {
            this.addNotification({
              type: NotificationType.INFO,
              message: value.content,
              dialog: true,
              title: value.title,
              subtitle: value.subtitle,
            })
          })
        }
        else {
          this.addSuccessNotification(`Receipts #${receipts.receipts_id} updated successfully`)
        }
      }
    },
    async handleSetPurchaseOrder(po: any) {
      const id = this.getActiveReceipts.id;
      if (this.getActiveReceipts.record.receipts_id) {
        await this.updateReceiptsById({
          id: this.getActiveReceipts.id,
          receipts: this.getActiveReceipts.record,
          po: po,
        });
        return;
      } else {
        await this.createReceiptsFromPurchaseOrder({
          po: po,
          id: id,
        });
      }
    },
    handleSetReceipts({ receipts, po }: { receipts: Receipts; po: PO }) {
      this.updateReceiptsById({
        id: this.getActiveReceipts.id,
        receipts: receipts,
        po: po ?? this.getActiveReceipts.po,
      });
    },
  },
});
