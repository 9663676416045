import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Column = _resolveComponent("Column")!
  const _component_Button = _resolveComponent("Button")!
  const _component_DataTable = _resolveComponent("DataTable")!
  const _component_Dialog = _resolveComponent("Dialog")!
  const _component_EmailFileDialog = _resolveComponent("EmailFileDialog")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", null, [
      _createVNode(_component_Dialog, {
        visible: _ctx.showDialog,
        modal: "",
        header: "Form Options",
        closable: false
      }, {
        footer: _withCtx(() => [
          _createVNode(_component_Button, {
            label: "Close",
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.showDialog = false)),
            autofocus: ""
          })
        ]),
        default: _withCtx(() => [
          _createVNode(_component_DataTable, { value: _ctx.formOptions }, {
            default: _withCtx(() => [
              _createVNode(_component_Column, { field: "option_text" }),
              _createVNode(_component_Column, null, {
                body: _withCtx((slotProps) => [
                  _createVNode(_component_Button, {
                    icon: _ctx.getIconForOptionType(slotProps.data.parameter_name_items),
                    onClick: ($event: any) => (_ctx.triggerFormOption(slotProps.data))
                  }, null, 8, ["icon", "onClick"])
                ]),
                _: 1
              })
            ]),
            _: 1
          }, 8, ["value"])
        ]),
        _: 1
      }, 8, ["visible"])
    ]),
    _createVNode(_component_EmailFileDialog, {
      show: _ctx.showEmailReceiptDialog,
      header: _ctx.optionText,
      onHide: _cache[1] || (_cache[1] = 
      (isHidden) => {
        _ctx.showEmailReceiptDialog = isHidden;
      }
    ),
      fileId: _ctx.currentReceiptId,
      fileName: _ctx.currentReceiptId,
      onOnEmail: _ctx.sendEmail,
      emailList: _ctx.emailList
    }, null, 8, ["show", "header", "fileId", "fileName", "onOnEmail", "emailList"])
  ], 64))
}