

  import FormControl from '@/types/state/formcontrol';
  import Button from 'primevue/button';
  import Column from 'primevue/column';
  import DataTable from 'primevue/datatable';
  import Dialog from 'primevue/dialog';
  import EmailFileDialog from '@/components/UI/EmailFileDialog.vue';
  import { defineComponent } from 'vue';
  import { mapGetters } from 'vuex';


  export default defineComponent({
  components: {
    Column,
    DataTable,
    Dialog,
    Button,
    EmailFileDialog
  },
  data() {
    return {
      showDialog: false,
      showEmailReceiptDialog: false,
      optionText: "",
      emailList: [] as string[],
      reportName: "",
      pageWidth: ""
    };
  },
  props: {
    formName: {
      type: String,
      required: true,
    },
    visible: {
      type: Boolean,
      default: false,
    },
    currentReceiptId: {
      type: String,
      required: true,
    },
  },
  emits: ["sendEmail", "downloadPdf"], 
  methods: {
    sendEmail(data: any) {
      //send email
      this.$emit("sendEmail", this.currentReceiptId, this.reportName, this.pageWidth, data);
    },
    triggerFormOption(formOption: any) {
      const emailFormAttribute = formOption.parameter_name_items.find((item: any) => item.parameter_name === "Email Address")
      this.reportName = formOption.form_procedure
      this.pageWidth = formOption.parameter_name_items.find((item: any) => item.parameter_name === "Page Width")?.parameter_setting
      if(emailFormAttribute) {
        //pop email dialog with emailForm.parameter_settings containing the email addresses
        this.optionText = formOption.option_text
        if (emailFormAttribute.parameter_setting) {
          this.emailList = emailFormAttribute.parameter_setting.split(';')
        }
        this.showEmailReceiptDialog = true
      }
      else {
        this.$emit("downloadPdf", this.currentReceiptId, this.reportName, this.pageWidth);
      }
    },
    getIconForOptionType(paramaters: any) {
      return paramaters.some((item: any) => item.parameter_name === "Email Address")
        ? 'pi pi-envelope'
        : 'pi pi-download'
    },
  },
  computed: {
    ...mapGetters({
        getFormItems: "control/getFormItems",
    }),

    formOptions() : any {
      const formItems = this.getFormItems as FormControl
      return formItems.entry_procedure_items?.filter(
        (item: any) => item.entry_procedure === this.formName);
    },
  },
  watch: {
    visible(newVal) {
        this.showDialog = newVal;
    },
  },
});
